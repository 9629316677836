<script>
    export let auction;
    import { inertia } from '@inertiajs/svelte';
    import { Icon, Bell, Users } from 'svelte-hero-icons';
    import { Button } from '$lib/components/ui/button';
    import { Input } from '$lib/components/ui/input';

    export let items = [
        {
            label: 'Bidding',
        },
        {
            label: 'User Info',
        },
        {
            label: 'Auction Setting',
        },
    ];
    export let activeTabValue = 'Bidding';
    const tabChange = (tabValue) => (activeTabValue = tabValue);
    function handleClick() {
        alert('working');
    }
</script>

<main class="bg-white p-6">
    <h1 class="mb-4 text-2xl font-bold">User Auction</h1>
    <!-- <ul class="space-y-2">
      <li><strong>ID:</strong> {auction.id}</li>
      <li><strong>Current Bid ID:</strong> {auction.current_bid_id}</li>
      <li><strong>Offering ID:</strong> {auction.offering_id}</li>
      <li><strong>Post ID:</strong> {auction.post_id}</li>
      <li><strong>User ID:</strong> {auction.user_id}</li>
      <li><strong>Created At:</strong> {new Date(auction.created_at).toLocaleString()}</li>
      <li><strong>End Time:</strong> {auction.end_time ? new Date(auction.end_time).toLocaleString() : 'N/A'}</li>
      <li><strong>Item Type:</strong> {auction.item_type}</li>
      <li><strong>Overtime:</strong> {auction.overtime}</li>
      <li><strong>Selling Mode:</strong> {auction.selling_mode}</li>
      <li><strong>Start Price:</strong> {auction.start_price}</li>
      <li><strong>Start Time:</strong> {new Date(auction.start_time).toLocaleString()}</li>
      <li><strong>Updated At:</strong> {new Date(auction.updated_at).toLocaleString()}</li>
    </ul> -->
    <div class="d-flex">
        <div class="profileImage w-80">
            <img src="https://via.placeholder.com/350x350" alt="profile Image" class="w-100" />
        </div>
        <div class="auctionDetail w-100">
            <div class="col-12">
                <div class="border-bottom mb-4">
                    <div class="d-flex items-center justify-between pb-4">
                        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                            {#each items as item}
                                <a
                                    id="pills-home-tab"
                                    class="{activeTabValue === item.label
                                        ? 'cursor-pointer whitespace-nowrap border-b-2 border-l-0 border-r-0 border-t-0 border-solid border-indigo-500 px-1 py-2 text-base font-semibold text-indigo-600'
                                        : 'cursor-pointer whitespace-nowrap border-b-2 border-l-0 border-r-0 border-t-0 border-solid border-transparent px-1 py-2 text-base font-semibold text-gray-500 hover:border-gray-300 hover:text-gray-700'}"
                                    on:click="{tabChange(item.label)}"
                                >
                                    {item.label}
                                </a>
                            {/each}
                        </nav>
                        <span class="rounded-md border bg-white px-2 text-center font-semibold">12.20.13</span>
                    </div>
                </div>
                <div class="tabContentHead d-flex mb-3 items-center justify-between">
                    <a href="{window.location.host + '/' + user.username}" target="blank">
                        <h6 class="m-0 font-bold uppercase">user name (22)</h6>
                    </a>
                    <div class="headBtns d-flex items-center">
                        <Button variant="outline" size="sm" on:click="{handleClick}"
                            ><Icon src="{Bell}" size="20" /></Button
                        >
                        <span class="ml-2 rounded-md border bg-white px-2 py-1 text-center font-semibold">12.20.13</span
                        >
                    </div>
                </div>
                <!-- Bidding Tab Content -->
                {#if activeTabValue === 'Bidding'}
                    <div class="bidDetail d-flex mb-3 items-center justify-between">
                        <div class="currentBid w-75 rounded-lg bg-gray-100 py-2 text-center text-lg">$5</div>
                        <div
                            class="holdBid w-25 ml-3 rounded-lg border border-bmn-500 bg-bmn-100 bg-gray-100 py-2 text-center font-bold"
                        >
                            You hold the bid!
                        </div>
                    </div>
                    <div class="bidHeight border-bottom mb-4">
                        <p class="font-bold">Pick a bid height.</p>
                        <div class="bidDetail d-flex mb-3 items-center justify-between">
                            <Input type="text" value="{'0'}" class="w-75 text-bold bg-gray-200 text-center text-xl" />
                            <Button variant="outline" class="w-25 ml-3 rounded-lg " size="lg">Place Bid</Button>
                        </div>
                    </div>
                    <div class="biddingHistory">
                        <div class="bidHistoryHead d-flex mb-3 items-center justify-between">
                            <p class="m-0 text-sm font-bold text-bmn-500">Show bidding History</p>
                            <div class="bidHistoryStats text-sm">[4 Bids] [3 Biders]</div>
                        </div>
                        <ul class="m-0 p-0">
                            <li class="d-flex mb-2 items-center justify-between rounded bg-gray-100 p-2">
                                <div class="bidHistoryItemName d-flex items-center">
                                    <div class="bidHistoryavatar mr-2 overflow-hidden rounded">
                                        <img src="https://via.placeholder.com/50x50" alt="" />
                                    </div>
                                    User
                                </div>
                                <div class="bidHistoryItemdateTime text-sm">10.01.2024 - 13:37</div>
                                <div class="bidHistoryItemPrice text-xl">$250</div>
                            </li>
                        </ul>
                    </div>
                {/if}
                {#if activeTabValue === 'User Info'}
                    <h6 class=" font-bold uppercase">Description</h6>
                    <p class="text-sm">
                        LoreLorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc id aliquam
                        tincidunt, nisl nunc tincidunt nunc, nec tincidunt nunc nunc id nunc. Sed euismod, nunc id
                        aliquam tincidunt, nisl nunc tincidunt nunc, nec tincidunt nunc nunc id nunc. Donec auctor, nunc
                        id aliquam tincidunt, nisl nunc tincidunt nunc, nec tincidunt nunc nunc id nunc. Fusce euismod,
                        nunc id aliquam tincidunt, nisl nunc tincidunt nunc, nec tincidunt nunc nunc id nunc. Curabitur
                        auctor, nunc id aliquam tincidunt, nisl nunc tincidunt nunc, nec tincidunt nunc nunc id nunc.
                    </p>
                    <ul>
                        <li class="d-flex justify-between p-2 text-sm">
                            <span>Totel Posts</span>
                            <span>02</span>
                        </li>
                        <li class="d-flex justify-between bg-gray-100 p-2 text-sm">
                            <span>Totel Subscribers</span>
                            <span>02</span>
                        </li>
                        <li class="d-flex justify-between p-2 text-sm">
                            <span>Registration Date</span>
                            <span>22.09.2023</span>
                        </li>
                        <li class="d-flex justify-between bg-gray-100 p-2 text-sm">
                            <span>Message Reply Rate</span>
                            <span>100%</span>
                        </li>
                        <li class="d-flex justify-between p-2 text-sm">
                            <span>Bought Points</span>
                            <span>$ 0</span>
                        </li>
                    </ul>
                {/if}
                {#if activeTabValue === 'Auction Setting'}
                    <ul class="mb-4">
                        <li class="d-flex justify-between p-2 text-sm">
                            <span>Extension Time</span>
                            <span>5 mins</span>
                        </li>
                        <li class="d-flex justify-between bg-gray-100 p-2 text-sm">
                            <span>Initial Countdown</span>
                            <span>22.00.00</span>
                        </li>
                        <li class="d-flex justify-between p-2 text-sm">
                            <span>Start Time</span>
                            <span>22:00</span>
                        </li>
                        <li class="d-flex justify-between bg-gray-100 p-2 text-sm">
                            <span>End Time</span>
                            <span>22.00</span>
                        </li>
                    </ul>
                    <p class="text-sm">Reminder notification settings</p>
                    <div class="reminders d-flex justify-between">
                        <div class="timeBaseReminder w-60">
                            <p class="text-sm font-bold">Time Based Reminders</p>
                            <div class="d-flex">
                                <span class="d-block mb-2 mr-2 rounded bg-gray-100 p-2 text-sm">10.00</span>
                                <span class="d-block mb-2 mr-2 rounded bg-gray-100 p-2 text-sm">10.00</span>
                                <span class="d-block mb-2 mr-2 rounded bg-gray-100 p-2 text-sm">10.00</span>
                            </div>
                        </div>
                        <div class="eventBaseReminder w-60">
                            <p class="text-sm font-bold">Event Based Reminders</p>
                            <div class="d-flex flex-wrap">
                                <span class="d-block mb-2 mr-2 whitespace-nowrap rounded bg-gray-100 p-2 text-sm"
                                    >The extension timer is hit</span
                                >
                                <span class="d-block mb-2 mr-2 whitespace-nowrap rounded bg-gray-100 p-2 text-sm"
                                    >A bid is posted</span
                                >
                            </div>
                        </div>
                    </div>
                    <p class="text-sm">Create Notification for this auction.</p>
                    <Button variant="outline" size="sm" class="w-100">Remember</Button>
                {/if}
            </div>
        </div>
    </div>
</main>
